/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import TitleImg from 'static/welfare/Title.png';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';
import { motion } from 'framer-motion';

const Title = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    margin-top: 120px;
    padding: 0px 60px;
    .box {
      border-radius: 48px;

      background: radial-gradient(circle at 50% 450%, #fff, #111927);
      padding-top: 120px;
      .container {
        width: 1352px;
        margin: 0 auto;
        > .title {
          font-size: 80px;
          font-style: normal;
          font-weight: 800;
          line-height: 130%;
          text-align: center;
          color: #fff;
          span {
            background: linear-gradient(90deg, #dcf2ff 0.24%, #9ed7fa 100.24%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        > .subTitle {
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 45px;
          margin-top: 24px;
          text-align: center;
        }
        > .buttonContainer {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          .downloadButton {
            margin-top: 32px;
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #4c6c82;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
          .welfareButton {
            margin-left: 24px;
            margin-top: 32px;
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            /* margin: 51px auto 0px auto; */
            font-size: 25px;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #72aed3;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
        }
        > .img {
          width: 900x;
          flex-shrink: 0;
          margin-top: 40px;
          padding-bottom: -1px;
          img {
            width: 100%;
            vertical-align: bottom;
          }
        }
      }
    }
    ${mq.mobile} {
      margin-top: 0;
      padding: 48px 18px 18px 18px;
      .box {
        border-radius: 24px;
        padding-top: 80px;
        .container {
          width: 100%;
          padding: 0px 20px 0px 20px;
          > .title {
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 52px;
          }
          > .subTitle {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
          }
          > .buttonContainer {
            flex-direction: column-reverse;
            align-items: center;
            .downloadButton,
            .welfareButton {
              width: 175px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              padding: 8px 8px 8px 20px;
              margin-left: 0;
              margin-top: 0;
              .arrow {
                width: 30px;
                height: 30px;
              }
            }
            .downloadButton {
              margin-top: 12px;
            }
          }
          > .img {
            width: 269x;
            margin-top: 40px;
            padding-bottom: -1px;
            img {
              width: 100%;
              vertical-align: bottom;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={TitleStyle}>
      <motion.div
        animate={{
          opacity: [0.1, 0.3, 0.5, 0.7, 1],
          transition: {
            duration: 1.5,
          },
        }}
        id="welfareTitle"
        className="box"
      >
        <div className="container">
          <h2 className="title">
            임직원 <span> 복지몰</span>
          </h2>
          <div className="subTitle">
            도입 · 운영 걱정은 줄이고
            <br />
            만족도는 높이는 몰파이 복지몰
          </div>
          <div className="buttonContainer">
            <a
              href="https://partner.mallpie.biz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="welfareButton">
                무료로 개설 시작
                <div className="arrow">
                  <ArrowRight className="arrow-img" />
                </div>
              </button>
            </a>
          </div>
          <div className="img">
            <img
              src={TitleImg}
              alt="도입 · 운영 걱정은 줄이고 만족도는 높이는 몰파이 복지몰 | 이미지"
              title="도입 · 운영 걱정은 줄이고 만족도는 높이는 몰파이 복지몰"
            />
          </div>
        </div>{' '}
      </motion.div>
    </div>
  );
};

export default Title;
