/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { motion } from 'framer-motion';

const Section4 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section4Style = css`
    margin-top: 60px;
    padding: 0px 60px;
    min-width: 1352px;
    .box {
      border-radius: 48px;
      background: #000;
      padding: 160px 0;
      position: relative;
      overflow: hidden;
      .container {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        > .title {
          font-size: 56px;
          font-style: normal;
          font-weight: 800;
          line-height: 76px;
          text-align: center;
          color: #fff;
          span {
            color: #72aed3;
          }
        }
        > .desc {
          text-align: center;
          margin-top: 56px;
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
        }
        ul {
          margin-top: 56px;
          display: flex;
          justify-content: center;
          li {
            text-align: center;
            border-radius: 420px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: linear-gradient(
              180deg,
              rgba(135, 176, 202, 0.1) 0%,
              rgba(135, 176, 202, 0.1) 100%
            );
            width: 420px;
            height: 420px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &:nth-of-type(1) {
              margin-right: -60px;
            }
            &:nth-of-type(3) {
              margin-left: -60px;
            }
            .step {
              color: #72aed3;
              font-size: 24px;
              font-weight: 800;
              line-height: 32px;
            }
            .title {
              color: #fff;
              text-align: center;
              font-size: 36px;
              font-weight: 700;
              line-height: 48px;
            }
            .desc {
              margin-top: 20px;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              color: #72aed3;
            }
          }
        }
      }
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 40%;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #092436 0%,
          rgba(228, 236, 241, 0) 100%
        );
      }
    }
    ${mq.mobile} {
      margin-top: 20px;
      padding: 0px 20px;
      min-width: 0;
      .box {
        border-radius: 48px;
        background: #000;
        padding: 64px 0;
        .container {
          > .title {
            font-size: 24px;
            line-height: 36px;
          }
          > .desc {
            margin-top: 36px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          ul {
            margin-top: 56px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            li {
              border-radius: 50%;
              width: 260px;
              height: 260px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              &:nth-of-type(1) {
                margin-right: 0;
                margin-bottom: -40px;
              }
              &:nth-of-type(3) {
                margin-top: -40px;
                margin-left: 0;
              }
              .step {
                color: #72aed3;
                font-size: 14px;
                line-height: 20px;
              }
              .title {
                color: #fff;
                text-align: center;
                font-size: 20px;
                line-height: 30px;
              }
              .desc {
                margin-top: 8px;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                color: #72aed3;
              }
            }
          }
        }
        .bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 40%;
          background: radial-gradient(
            50% 50% at 50% 50%,
            #092436 0%,
            rgba(228, 236, 241, 0) 100%
          );
        }
      }
    }
  `;
  const xlVariants = {
    offscreen: {
      x: -200,
      y: 0,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  const xrVariants = {
    offscreen: {
      x: 200,
      y: 0,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 200,
      x: 0,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
    },
  };
  return (
    <div css={Section4Style}>
      <div className="box">
        <div className="container">
          <h2 className="title">
            <span>가입 즉시, 이용 가능한 복지몰</span>
            <br />
            복잡한 절차 없이 바로 도입하세요
          </h2>

          <ul>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              variants={isMobile ? yVariants : xlVariants}
              transition={{ duration: isMobile ? 0.3 : 0.8 }}
            >
              <div className="step">STEP 1</div>
              <div className="title">
                회원가입 및<br />
                복지몰 개설
              </div>
              <div className="desc">
                사업자 정보 입력 및
                <br />
                복지몰 주소 설정
              </div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              variants={yVariants}
              transition={{ duration: isMobile ? 0.5 : 0.8 }}
            >
              <div className="step">STEP 2</div>
              <div className="title">
                쇼핑몰 생성 및
                <br />
                어드민 제공
              </div>
              <div className="desc">
                기업 전용 쇼핑몰 및
                <br />
                관리자 어드민 제공
              </div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              variants={isMobile ? yVariants : xrVariants}
              transition={{ duration: isMobile ? 0.8 : 0.8 }}
            >
              <div className="step">STEP 3</div>
              <div className="title">
                복지몰
                <br />
                즉시 이용
              </div>
              <div className="desc">
                임직원 초대 및
                <br />
                복지 포인트 부여
              </div>
            </motion.li>
          </ul>
          <div className="desc">
            *기본 개설 과금 방식은 선불형이며,{isMobile && <br />}
            이후 협의에 따라 후불형으로 전환이 가능합니다.
          </div>
        </div>

        <div className="bg" />
      </div>
    </div>
  );
};

export default Section4;
